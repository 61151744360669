

import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { AuthService } from '../auth/auth.service';
import { ErrorService } from '../auth/error.service';
const apiShomeBaseUrl = environment.apishomeBase;
@Injectable()
export class ApartmentApiService {
    private _http = inject(HttpClient);
    private _authService = inject(AuthService);
    private _messageService = inject(MessageService);
    private _errorService = inject(ErrorService)
    constructor() {
    }
    options = {
        // headers: new HttpHeaders({
        //     Authorization: this._authService.getAuthorizationHeaderValue(),
        //     'Content-Type': 'application/json',
        // })
    };


    getObjectList(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetObjectList?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentFilter(): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentFilter`, this.options).pipe(this.ErrorAsync());
    }

    getApartmentPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentPage?` + params, this.options).pipe(this.ErrorAsync());
    }
    
    getApartmentInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentChangeRoomCodeInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentChangeRoomCodeInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentFamilyMemberPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentFamilyMemberPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentHouseholdPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentHouseholdPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentFamilyCardPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetCardPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentVehiclePage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/vehicle/GetApartmentVehiclePage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentVehicleInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/vehicle/GetApartmentVehicleInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentRequestPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/request/GetApartmentRequestPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentVehicleCardPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetVehicleCardPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentFamilyMemberInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentFamilyMemberInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentFeeInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/feeservice/GetApartmentFeeInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    setApartmentFeeInfoDraft(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/feeservice/SetApartmentFeeInfoDraft`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    getApartmentReceiptPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetReceiptPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentHouseholdInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentHouseholdInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentFamilyCardInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetCardInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getAuthenticateAdmin(): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/user/AuthenticateAdmin`, this.options).pipe(this.ErrorAsync());
    }

    getApartmentFamilyMemberChangHostInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentFamilyMemberChangHostInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentVehicleCardInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetVehicleCardInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentProfileReceiptInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/card/GetApartmentProfileReceiptInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    setApartmentFamilyMemberInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/apartment/SetApartmentFamilyMemberInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    setApartmentHouseholdInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/apartment/SetApartmentHouseholdInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    setApartmentFamilyMemberChangHostInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/apartment/SetApartmentFamilyMemberChangHostInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    setApartmentFamilyCardInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/card/SetCardInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    setApartmentVehicleCardInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/card/SetVehicleCardInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    setVehicleLocked(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/card/SetVehicleLocked?`+ queryParams, null, this.options).pipe(this.ErrorAsync());
    }

    delApartmentFamilyMember(queryParams: string): Observable<any> {
        return this._http.delete<any>(`${apiShomeBaseUrl}/api/v2/apartment/DeleteApartmentFamilyMember?` + queryParams, this.options).pipe(this.ErrorAsync());
    }

    setAuthFamilyMember(queryParams: any): Observable<any> {
        return this._http.put(`${apiShomeBaseUrl}/api/v2/apartment/SetAuthFamilyMember`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    getApartmentProfile(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v1/shome/GetApartmentProfile?` + params, this.options).pipe(this.ErrorAsync());
    }

    getSendNotificationPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v1/shome/GetSendNotificationPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getReceiptPageByApartment(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v1/shome/GetReceiptPageByApartment?` + params, this.options).pipe(this.ErrorAsync());
    }

    getReceiptByApartmentId(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/receipt/GetReceiptByApartmentId?` + params, this.options).pipe(this.ErrorAsync());
    }

    getServiceBillPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v1/shome/GetServiceBillPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getTicketInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/cardvehicle/GetTicketInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentHouseholdPageV1(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v1/shome/GetApartmentHouseholdPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getHouseholdPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetHouseholdPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentHouseholdFilter(): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentHouseholdFilter`, this.options).pipe(this.ErrorAsync());
    }

    setApartmentChangeRoomCodeInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/apartment/SetApartmentChangeRoomCodeInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    setApartmentFeeInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/feeservice/SetApartmentFeeInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    getBuildingList(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetBuildingList?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentSearch(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v1/shome/GetApartmentSearch?` + params, this.options).pipe(this.ErrorAsync());
    }

    getApartmentSearchV2(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentSearch?` + params, this.options).pipe(this.ErrorAsync());
    }

    getProjectInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetProjectInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    setProjectInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/apartment/SetProjectInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    getApartmentAddInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetApartmentAddInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    setApartmentAddInfo(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/apartment/SetApartmentAddInfo`, queryParams, this.options).pipe(this.ErrorAsync());
    }
    
    getFamilyMemberByPhoneInfo(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetFamilyMemberByPhoneInfo?` + params, this.options).pipe(this.ErrorAsync());
    }

    setApartmentFamilyMemberInfoDraft(queryParams: string): Observable<any> {
        return this._http.post(`${apiShomeBaseUrl}/api/v2/apartment/SetApartmentFamilyMemberInfoDraft`, queryParams, this.options).pipe(this.ErrorAsync());
    }

    getInvoiceHistoryByApartmentIdPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/Invoice/GetInvoiceHistoryByApartmentIdPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getReceiptByApartmentIdPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/receipt/GetReceiptByApartmentIdPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getHistoryNotifyByApartmentPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetHistoryNotifyByApartmentPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getHistoryEmailByApartmentPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetHistoryEmailByApartmentPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    getHistorySmsByApartmentPage(params: string): Observable<any> {
        return this._http.get<any>(`${apiShomeBaseUrl}/api/v2/apartment/GetHistorySmsByApartmentPage?` + params, this.options).pipe(this.ErrorAsync());
    }

    private ErrorAsync() {
        return catchError(error => {
            this.handleError(error)
            return of(error.error);
        })
    }

    private handleError(error: any) {
        this._errorService.setError(error.status);
        this._messageService.add({ severity: 'error', summary: 'Error Message', detail: error.error });
    }



}
