import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChatComponent } from './components/chat/chat.component';
import { VehicleGuestComponent } from './components/vehicle/vehicle-guest/vehicle-guest.component';
import { DefaultLayoutComponent } from './containers/default-layout';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { AuthCallbackComponent } from './shared/components/auth-callback/auth-callback.component';
@NgModule({
  imports: [
      RouterModule.forRoot([
          {
            path: '',
            redirectTo: 'home',
            pathMatch: 'full',
          },
          { path: 'auth-callback', component: AuthCallbackComponent },
          {
              path: '', component: DefaultLayoutComponent,
              children: [
                { path: 'home', loadChildren: () => import('../app/components/home/home.module').then(m => m.HomeModule) },
                { path: 'apartment',
                  loadChildren: () => import('../app/components/apartment/apartment.module').then(m => m.ApartmentModule) },
                { path: 'card/custcard',
                  loadChildren: () => import('../app/components/card/customercard/customer-card.module').then(m => m.CustomerCardModule)},
                // { path: 'expec-table',
                //   loadChildren: () => import('../app/components/expect-table/expect-table.module').then(m => m.ExpectTableModule) },
                { path: 'fee',
                  loadChildren: () => import('../app/components/fee/fee.module').then(m => m.FeeModule) },
                { path: 'request',
                  loadChildren: () => import('../app/components/request/page-request.module').then(m => m.PageRequestModule) },
                { path: 'setting',
                  loadChildren: () => import('../app/components/price-list/price-list.module').then(m => m.PriceListModule) },
                { path: 'manager',
                  loadChildren: () => import('../app/components/manager/manager.module').then(m => m.ManagerModule) },
                { path: 'apartment/page-notify',
                  loadChildren: () => import('../app/components/page-notify/page-notify.module').then(m => m.PageNotifyModule) },
                { path: 'card/vehicle-daily',
                  loadChildren: () => import('../app/components/vehicle-daily/vehicle-daily.module').then(m => m.VehicleDailyModule) },
                { path: 'card-manager',
                  loadChildren: () => import('../app/components/card/card-manager/card-manager.module').then(m => m.CardManagerModule) },
                  { path: 'quan-ly-nguoi-dung',
                  loadChildren: () => import('../app/components/quan-ly-nguoi-dung/quan-ly-nguoi-dung.module').then(m => m.QuanLyNguoiDungModule) },
                { path: 'card/vehicle-guest', component: VehicleGuestComponent, data: { title: '_Danh sách vé xe khách ngoài_'} },
                { path: 'chat', component: ChatComponent, data: { title: '_Quản lý chat_'} },
              ], canActivate: [AuthGuardService]
          },

      ])
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
